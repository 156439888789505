import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import { AttentionSeeker } from "react-awesome-reveal";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import { Chart } from "react-google-charts";
import { ApplicationLayout } from "../layouts/ApplicationLayout";
import WarningTooltip from "../common/WarningTooltip";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import OnboardingSteps from "./OnboardingSteps";
import CashflowItem from "../cashflows/CashflowItem";
import TransactionRecord from "../transactions/TransactionRecord";
import Dropdown from "../common/Dropdown";
import AlertDrawer from "./AlertDrawer";
import routes from "../../utils/routes";
import currencyName, {
  numberToCurrencyNoUnit,
} from "../../utils/currency-name";
import CURRENCY_ICONS from "../../utils/currency-icons";

const DEFAULT_DISPLAY_ITEMS = 3;
const CURRENCIES = i18n.t("currencies");
const PROJECTION_CHART_OPTIONS = {
  isStacked: true,
  legend: { position: "top", alignment: "center" },
  intervals: { style: "none" },
  bar: { groupWidth: "90%" },
  hAxis: { minorGridlines: { color: "transparent" } },
  vAxis: {
    minValue: 0,
    minorGridlines: { color: "transparent" },
    format: "decimal",
  },
  chartArea: { left: 80, top: 18, bottom: 20, width: "100%", height: "100%" },
  focusTarget: "category",
  tooltip: { isHtml: true },
};
const PROJECTION_COLORS = [
  "#0766AD",
  "#29ADB2",
  "#213363",
  "#C5E898",
  "#8EAC50",
  "#17594A",
  "#D3D04F",
];
// see: https://mycolor.space/?hex=%23312E81&sub=1

const ASSET_CHART_OPTIONS = {
  pieHole: 0.4,
  chartArea: { width: "100%", height: "100%" },
};
const ASSET_COLORS = ["#03045e", "#0077b6", "#00b4d8", "#90e0ef"];

function TotalAssetCard({ projected_balance, fx_rates }) {
  const availableCurrencies = CURRENCIES.filter((currency) =>
    [...new Set(projected_balance.map((item) => item.currency))].includes(
      currency.value,
    ),
  ).map((currency) => currency.value);
  const [currency, setCurrency] = useState(availableCurrencies[0]);
  const onChangeCurrency = (e) => setCurrency(e.target.value);
  const assets = projected_balance.map((balance) => ({
    bank: balance.bank_account.name,
    currency: balance.currency,
    amount: balance.latest_balance?.amount,
    profit: balance.latest_balance?.profit,
    yield_pa: balance.bank.profile.portfolio_info?.find(
      (item) => item.currency == balance.currency,
    )?.yield_pa,
    converted_amount:
      balance.currency == currency
        ? balance.latest_balance?.amount || 0
        : (balance.latest_balance?.amount || 0) *
          fx_rates[balance.currency][currency],
  }));
  const data = [[i18n.t("currency"), i18n.t("amount")]].concat(
    availableCurrencies
      .map((_currency) =>
        assets.reduce(
          (r, asset) =>
            asset.currency == _currency
              ? {
                  currency: _currency,
                  amount: (asset ? asset.amount : 0) + (r.amount || 0),
                  converted_amount:
                    (asset ? asset.converted_amount : 0) +
                    (r.converted_amount || 0),
                }
              : r,
          {},
        ),
      )
      .map((item) => [
        currencyName(item.currency),
        {
          v: item.converted_amount,
          f: numberToCurrencyNoUnit(item.converted_amount, item.currency),
        },
      ]),
  );
  const totalAmount = assets.reduce(
    (r, asset) => r + asset.converted_amount,
    0,
  );
  const profitByCurrency = availableCurrencies.map((_currency) =>
    assets.reduce(
      (r, asset) =>
        asset.currency == _currency
          ? {
              currency: _currency,
              currency_display: currencyName(_currency),
              profit: (asset.profit || 0) + (r.profit || 0),
              yield_pa: asset.yield_pa || r.yield_pa,
            }
          : r,
      {},
    ),
  );

  return (
    <Grid container spacing={2} sx={{ marginLeft: "-16px!important" }}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title={i18n.t("total_assets", {
              currency: currencyName(currency),
              amount: numberToCurrencyNoUnit(totalAmount, currency),
            })}
            action={
              <Dropdown
                size="small"
                variant="outlined"
                name="currency"
                values={CURRENCIES.filter((item) =>
                  availableCurrencies.includes(item.value),
                )}
                value={currency}
                onChange={onChangeCurrency}
                sx={{ marginTop: "-12px!important" }}
              />
            }
          />
          <Divider />
          <CardContent>
            <Chart
              width="100%"
              height="250px"
              chartType="PieChart"
              data={data}
              options={{
                ...ASSET_CHART_OPTIONS,
                colors: ASSET_COLORS,
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title={i18n.t("investment_returns")} />
          <Divider />
          <CardContent sx={{ minHeight: { md: 250 + 32 * 2, sm: "auto" } }}>
            <Stack spacing={2}>
              {profitByCurrency.map((profit, i) => (
                <div key={i} style={{ display: "flex", alignItems: "center" }}>
                  <Avatar src={CURRENCY_ICONS[profit.currency]} />
                  <Typography
                    variant="body1"
                    sx={{ ml: 2, width: "100%" }}
                    component="div"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <I18n estimated_yield />
                      <div>
                        <span style={{ fontWeight: "bold", fontSize: "130%" }}>
                          {profit.yield_pa || "-"}
                        </span>
                        %p.a
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <I18n total_return />
                      <div>
                        {profit.currency_display}&nbsp;
                        <span style={{ fontWeight: "bold", fontSize: "130%" }}>
                          <I18n
                            l="currency"
                            v={profit.profit}
                            currency={profit.currency}
                            noUnit
                          />
                        </span>
                      </div>
                    </div>
                  </Typography>
                </div>
              ))}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function AssetCard({
  currency,
  cashflow_items,
  transaction_records,
  projected_balance,
  fx_rates,
  pending_cashflow_items,
  failed_cashflow_items,
  failed_transactions,
  account_balance_warnings,
  bank_account_warnings,
  openAlertDrawer,
}) {
  const balances = projected_balance
    .filter((item) => item.currency == currency)
    .sort((a, b) => (a.bank.bank_type > b.bank.bank_type ? 1 : -1))
    .map((item) =>
      Object.assign(item, {
        hash_balance: item.balance.reduce(
          (r, record) =>
            Object.assign(r, { [record.date]: Math.max(record.balance, 0) }),
          {},
        ),
      }),
    );
  const dates = [
    ...new Set(
      balances.map((item) => item.balance.map((record) => record.date)).flat(),
    ),
  ].sort();
  // complete missing dates
  balances.forEach((item) => {
    dates.forEach((date, i) => {
      if (i > 0 && typeof item.hash_balance[date] === "undefined")
        item.hash_balance[date] = item.hash_balance[dates[i - 1]];
    });
  });
  /* generate data for chart
      1st row: header
      other rows: date, tooltip HTML, balance for each bank account
  */
  const data = [
    ["", { type: "string", role: "tooltip", p: { html: true } }].concat(
      balances.map((item) => item.bank_account.short_name),
    ),
  ].concat(
    dates.map((date) =>
      [
        {
          v: new Date(date),
          f: i18n.toTime("date.formats.short", new Date(date)),
        },
        // generate tooltip HTML
        "<div style='font-family:Arial;font-size:12px;color:#000000;opacity:1;margin:6px;font-style:none;text-decoration:none;width:168px;'>" +
          "<div style='display:flex;justify-content:space-between;'><b>" +
          // display date
          i18n.toTime("date.formats.short", new Date(date)) +
          "</b></div>" +
          balances
            .map(
              // display balance for each bank account
              (_item, j) =>
                "<div style='display:flex;justify-content:space-between;'>" +
                "<div style='white-space:nowrap;text-overflow:ellipsis;'>" +
                `<div style='height:6px;width:6px;background:${PROJECTION_COLORS[j]};display:inline-block;margin-right:3px;'></div>` +
                _item.bank_account.short_name +
                "</div>" +
                "<div><b>" +
                (_item.hash_balance[date]
                  ? i18n.numberToRounded(_item.hash_balance[date], {
                      delimiter: ",",
                      precision: 2,
                    })
                  : "-") +
                "</b></div></div>",
            )
            .join("") +
          "<div style='display:flex;justify-content:space-between;'>" +
          "<div style='margin-left:20px;'><b>Total</b></div>" +
          "<div><b>" +
          // display total balance
          i18n.numberToRounded(
            balances.reduce(
              (r, _item) =>
                r + (_item.hash_balance[date] ? _item.hash_balance[date] : 0),
              0,
            ),
            { delimiter: ",", precision: 2 },
          ) +
          "</b></div></div>",
      ].concat(balances.map((_item) => _item.hash_balance[date] || 0)),
    ),
  );
  const projectedItems = cashflow_items
    .filter((item) => item.bank_account.currency == currency)
    .concat(
      transaction_records.filter(
        (item) =>
          item.src_currency == currency || item.dst_currency == currency,
      ),
    )
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
    );
  const showWarning =
    pending_cashflow_items.length > 0 ||
    failed_cashflow_items.length > 0 ||
    failed_transactions.length > 0 ||
    account_balance_warnings.length > 0 ||
    bank_account_warnings.length > 0;

  return (
    <Card>
      <CardHeader
        title={i18n.t("projection_for_currency", {
          currency: currencyName(currency),
        })}
        action={
          showWarning && (
            <AttentionSeeker effect="flash" delay={2000}>
              <WarningTooltip
                arrow
                placement="left"
                title={i18n.t("action_required")}
              >
                <IconButton onClick={openAlertDrawer} color="error">
                  <SvgIcon>
                    <ExclamationTriangleIcon />
                  </SvgIcon>
                </IconButton>
              </WarningTooltip>
            </AttentionSeeker>
          )
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2} sx={{ mb: 3, px: { md: 12, sm: 2 } }}>
          {balances.map((balance, i) => (
            <Grid key={i} item md={6} sm={12}>
              <Card
                sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <Avatar
                    variant="rounded"
                    src={balance.bank_account.image}
                    sx={{ width: 24, height: 24, mr: 1 }}
                  />
                  <Typography variant="body2">
                    {balance.bank_account.short_name}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    <I18n
                      l="currency"
                      v={balance.latest_balance?.amount || 0}
                      currency={balance.bank_account.currency}
                      noUnit
                    />
                  </Typography>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
        {(data.length > 1 && (
          <Chart
            width="100%"
            height="250px"
            chartType="AreaChart"
            data={data}
            options={{
              ...PROJECTION_CHART_OPTIONS,
              colors: PROJECTION_COLORS,
            }}
          />
        )) || <I18n no_data />}
        {projectedItems.length > 0 && (
          <Typography variant="body1" sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
            <I18n projected_cashflow_items />
          </Typography>
        )}
        <Stack>
          {projectedItems
            .slice(0, DEFAULT_DISPLAY_ITEMS)
            .map(
              (item, i) =>
                (item.date && <CashflowItem key={i} item={item} />) || (
                  <TransactionRecord
                    key={i}
                    fx_rates={fx_rates}
                    transaction={item}
                    currency={currency}
                  />
                ),
            )}
          <Button
            href={routes.transactions({ popup: currency })}
            sx={{ mt: 2 }}
          >
            <I18n go_to_cash_planning />
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default function ({
  cashflow_items,
  transaction_records,
  projected_balance,
  fx_rates,
  ...props
}) {
  const {
    pending_cashflow_items,
    failed_cashflow_items,
    failed_transactions,
    account_balance_warnings,
    bank_account_warnings,
    current_user,
  } = props;
  const [alertDrawer, setAlertDrawer] = useState(false);
  const openAlertDrawer = () => setAlertDrawer(true);
  const closeAlertDrawer = () => setAlertDrawer(false);
  const availableCurrencies = CURRENCIES.filter((currency) =>
    [...new Set(projected_balance.map((item) => item.currency))].includes(
      currency.value,
    ),
  ).map((currency) => currency.value);

  if (availableCurrencies.length == 0)
    // Onboarding process is not completed
    return (
      <ApplicationLayout {...props}>
        <Typography variant="h2">
          <I18n dashboard />
        </Typography>
        <OnboardingSteps />
      </ApplicationLayout>
    );

  return (
    <ApplicationLayout {...props}>
      <Stack spacing={2}>
        <Typography variant="h2">
          <I18n dashboard />
        </Typography>
        <Typography variant="subtitle2">
          <I18n dashboard_subtitle />
        </Typography>
        <TotalAssetCard
          projected_balance={projected_balance}
          fx_rates={fx_rates}
        />
        {availableCurrencies.map((currency, i) => (
          <AssetCard
            key={i}
            currency={currency}
            fx_rates={fx_rates}
            openAlertDrawer={openAlertDrawer}
            cashflow_items={cashflow_items}
            transaction_records={transaction_records}
            projected_balance={projected_balance}
            pending_cashflow_items={pending_cashflow_items.filter(
              (item) => item.bank_account.currency == currency,
            )}
            failed_cashflow_items={failed_cashflow_items.filter(
              (item) => item.bank_account.currency == currency,
            )}
            failed_transactions={failed_transactions.filter(
              (item) =>
                item.src_currency == currency || item.dst_currency == currency,
            )}
            account_balance_warnings={account_balance_warnings.filter(
              (item) => item.bank_account.currency == currency,
            )}
            bank_account_warnings={bank_account_warnings.filter(
              (item) => item.bank_account.currency == currency,
            )}
          />
        ))}
      </Stack>

      <AlertDrawer
        open={alertDrawer}
        onClose={closeAlertDrawer}
        pending_cashflow_items={pending_cashflow_items}
        failed_cashflow_items={failed_cashflow_items}
        failed_transactions={failed_transactions}
        account_balance_warnings={account_balance_warnings}
        bank_account_warnings={bank_account_warnings}
        current_user={current_user}
      />
    </ApplicationLayout>
  );
}
