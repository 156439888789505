import React, { useCallback, useState, useRef, useEffect } from "react";
import BellIcon from "@heroicons/react/24/solid/BellIcon";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import QuestionMarkCircleIcon from "@heroicons/react/24/solid/QuestionMarkCircleIcon";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AttentionSeeker } from "react-awesome-reveal";
import { alpha } from "@mui/material/styles";
import { AccountPopover } from "./AccountPopover";
import { NotificationDrawer } from "../notifications/NotificationDrawer";
import WarningTooltip from "../common/WarningTooltip";
import AlertDrawer from "../dashboard/AlertDrawer";
import Confirm from "../common/Confirm";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

const CASHWISE_HELPCENTER = "https://faq.cashwise.asia/";

export const TopNavigation = ({
  onNavOpen,
  current_user,
  account,
  notification,
  notifications,
  pending_cashflow_items,
  failed_cashflow_items,
  failed_transactions,
  account_balance_warnings,
  bank_account_warnings,
  show_alert,
}) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const anchorRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [alertDrawer, setAlertDrawer] = useState(false);
  const [alertTooltip, setAlertTooltip] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handlePopoverOpen = useCallback(() => setPopoverOpen(true), []);
  const handlePopoverClose = useCallback(() => setPopoverOpen(false), []);
  const handleNotificationOpen = useCallback(
    () => setNotificationOpen(true),
    [],
  );
  const handleNotificationClose = useCallback(
    () => setNotificationOpen(false),
    [],
  );
  const openAlertDrawer = () => {
    closeConfirm();
    setAlertDrawer(true);
  };
  const closeAlertDrawer = () => setAlertDrawer(false);
  const openAlertTooltip = () => setAlertTooltip(true);
  const closeAlertTooltip = () => setAlertTooltip(false);
  const closeConfirm = () => setConfirmOpen(false);
  const showWarning =
    pending_cashflow_items.length > 0 ||
    failed_cashflow_items.length > 0 ||
    failed_transactions.length > 0 ||
    account_balance_warnings.length > 0 ||
    bank_account_warnings.length > 0;
  useEffect(() => {
    setTimeout(() => setAlertTooltip(true), 1000);
    setTimeout(() => setAlertTooltip(false), 5000);
    if (show_alert) setTimeout(() => setConfirmOpen(true), 500);
  }, []);

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          left: {
            md: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            md: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!mdUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon>
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Typography variant="h6" sx={{ marginRight: "auto!important" }}>
            {account.name}
          </Typography>
          <Stack alignItems="center" direction="row" spacing={2}>
            {showWarning && (
              <AttentionSeeker effect="flash" delay={2000}>
                <WarningTooltip
                  arrow
                  open={alertTooltip}
                  onOpen={openAlertTooltip}
                  onClose={closeAlertTooltip}
                  title={i18n.t("action_required")}
                  placement="left"
                >
                  <IconButton color="error" onClick={openAlertDrawer}>
                    <SvgIcon>
                      <ExclamationTriangleIcon />
                    </SvgIcon>
                  </IconButton>
                </WarningTooltip>
              </AttentionSeeker>
            )}
            <Tooltip title={i18n.t("notifications")}>
              <IconButton onClick={handleNotificationOpen}>
                {notification && (
                  <Badge
                    badgeContent={4}
                    color={
                      (notification == "informational" && "info") ||
                      (notification == "warning" && "warning") ||
                      (notification == "critical" && "error")
                    }
                    variant="dot"
                  >
                    <SvgIcon>
                      <BellIcon />
                    </SvgIcon>
                  </Badge>
                )}
                {!notification && (
                  <SvgIcon>
                    <BellIcon />
                  </SvgIcon>
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title={i18n.t("help")}>
              <IconButton href={CASHWISE_HELPCENTER} target="_blank">
                <SvgIcon>
                  <QuestionMarkCircleIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Avatar
              onClick={handlePopoverOpen}
              ref={anchorRef}
              sx={{
                cursor: "pointer",
                height: 40,
                width: 40,
              }}
              src={current_user.image}
            />
          </Stack>
        </Stack>
      </Box>

      <NotificationDrawer
        notifications={notifications}
        open={notificationOpen}
        onClose={handleNotificationClose}
      />
      <AccountPopover
        anchorEl={anchorRef.current}
        open={popoverOpen}
        onClose={handlePopoverClose}
        account={account}
        current_user={current_user}
      />
      <AlertDrawer
        open={alertDrawer}
        onClose={closeAlertDrawer}
        pending_cashflow_items={pending_cashflow_items}
        failed_cashflow_items={failed_cashflow_items}
        failed_transactions={failed_transactions}
        account_balance_warnings={account_balance_warnings}
        bank_account_warnings={bank_account_warnings}
        current_user={current_user}
      />
      <Confirm
        open={confirmOpen}
        onClose={closeConfirm}
        title={i18n.t("action_required")}
        actions={[
          {
            label: i18n.t("continue"),
            onClick: openAlertDrawer,
            variant: "contained",
          },
        ]}
      >
        <I18n found_transaction_need_to_be_reviewed />
      </Confirm>
    </>
  );
};
