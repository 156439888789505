import React, { useState, createRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import AppCard from "./AppCard";
import BankCard from "../integrations/BankCard";
import Confirm from "../../common/Confirm";
import api from "../../../utils/api";
import Dropdown from "../../common/Dropdown";
import SubmitButton from "../../common/SubmitButton";
import RefreshButton from "../../common/RefreshButton";
import currencyName from "../../../utils/currency-name";

function EditDrawerContent({ app_integration, bank_accounts }) {
  const formRef = createRef();
  const [openDelete, setOpenDelete] = useState(false);
  const [openSwitch, setOpenSwitch] = useState(false);
  const [openPseudoBankAccount, setOpenPseudoBankAccount] = useState(false);
  const [appBankAccounts, setAppBankAccounts] = useState([]);
  const [retrievingBankAccounts, setRetrievingBankAccounts] = useState(false);
  const [disablePayouts, setDisablePayouts] = useState(
    app_integration.data?.disable_payouts ? "true" : "false",
  );
  const [active, setActive] = useState(app_integration.status == "enabled");
  const openDeleteConfirm = () => setOpenDelete(true);
  const onCloseDeleteConfirm = () => setOpenDelete(false);
  const toggleActive = () => {
    if (active) openSwitchConfirm();
    else setActive(!active);
  };
  const openSwitchConfirm = () => setOpenSwitch(true);
  const onCloseSwitchConfirm = () => setOpenSwitch(false);
  const onCloseSwitchConfirmWithSetActiveFalse = () => {
    setOpenSwitch(false);
    setActive(false);
  };
  const onClosePseudoBankAccountConfirm = () => setOpenPseudoBankAccount(false);
  const onDisablePayoutsChange = (event) =>
    setDisablePayouts(event.target.value);
  const appBankAccountList = (bank_account) => {
    // Find the bank account that is already mapped to the app integration
    const _bank_account = (app_integration.data?.bank_accounts || []).find(
      (__bank_account) => __bank_account.bank_account_id == bank_account.id,
    );
    return [_bank_account]
      .concat(
        // Concatenate the bank accounts that have the same currency as the bank account
        appBankAccounts.filter(
          (__bank_account) =>
            __bank_account.currency == bank_account.currency &&
            __bank_account.account_id != _bank_account?.account_id,
        ),
      )
      .filter((_item) => _item)
      .map((item) => ({
        // Create a list of bank accounts that can be selected
        value: `${item.account_id}:_:${item.name}:_:${item.currency}`,
        name: `${item.name} - ${currencyName(bank_account.currency)}`,
      }));
  };
  const retrieveBankAccounts = () => {
    api({
      url: routes.retrieve_bank_accounts_account_app_integrations(),
      method: "get",
      data: { identifier: app_integration.identifier },
      onStart: () => setRetrievingBankAccounts(true),
      onError: () => setRetrievingBankAccounts(false),
      onSuccess: (app_bank_accounts) => {
        setAppBankAccounts(app_bank_accounts);
        setRetrievingBankAccounts(false);
      },
    });
  };

  return (
    <>
      <Form
        ref={formRef}
        action={routes.account_app_integration({ id: app_integration.id })}
        method="put"
      >
        <Stack spacing={2}>
          <AppCard
            app={app_integration.app}
            action={
              <Switch checked={active} onChange={toggleActive} name="status" />
            }
          />
          {active && (
            <Card>
              <CardHeader
                title={i18n.t("bill_payment_mode")}
                subheader={i18n.t("bill_payment_mode_subtitle")}
              />
              <Divider />
              <CardContent>
                <FormControl>
                  <RadioGroup
                    name="disable_payouts"
                    value={disablePayouts}
                    onChange={onDisablePayoutsChange}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={i18n.t("app_integration_disable_payouts")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={i18n.t("app_integration_enable_payouts")}
                    />
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>
          )}
          {active && (
            <Card>
              <CardHeader
                title={i18n.t("bank_account_mapping")}
                subheader={i18n.t("bank_account_mapping_subtitle")}
              />
              <Divider />
              <CardContent>
                <Stack spacing={2}>
                  {bank_accounts
                    .filter(
                      (bank_account) =>
                        bank_account.bank_type == "transactional" &&
                        !bank_account.profile.suspended,
                    )
                    .map((bank_account) => (
                      <BankCard
                        key={bank_account.id}
                        bank={bank_account.bank}
                        action={
                          <div style={{ display: "flex", minWidth: 240 }}>
                            <Dropdown
                              required
                              fullWidth
                              variant="standard"
                              values={appBankAccountList(bank_account)}
                              defaultValue={
                                appBankAccountList(bank_account)[0]?.value
                              }
                              label={i18n.t("account_name")}
                              name={`bank_account[${bank_account.id}]`}
                            />
                            <RefreshButton
                              title={i18n.t("refresh")}
                              sx={{ alignSelf: "end", ml: 1 }}
                              onClick={() => retrieveBankAccounts()}
                              loading={retrievingBankAccounts}
                            />
                          </div>
                        }
                      >
                        <Typography variant="body2">
                          <I18n currency />
                          :&nbsp;&nbsp;&nbsp;
                          <b>{currencyName(bank_account.currency)}</b>
                        </Typography>
                      </BankCard>
                    ))}
                  {bank_accounts.filter(
                    (bank_account) =>
                      bank_account.bank_type == "transactional" &&
                      !bank_account.profile.suspended,
                  ).length == 0 && (
                    <Typography variant="subtitle2">
                      {i18n.t("no_bank_accounts")}
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          )}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <SubmitButton fullWidth variant="contained" type="submit">
              <I18n update />
            </SubmitButton>
            <Button
              color="error"
              onClick={openDeleteConfirm}
              sx={{ alignSelf: "flex-end" }}
            >
              <I18n delete />
            </Button>
          </Box>
        </Stack>
        <Confirm
          open={openDelete}
          onClose={onCloseDeleteConfirm}
          title={i18n.t("delete_app_integration")}
          actions={[
            {
              onClick: onCloseDeleteConfirm,
              label: i18n.t("cancel"),
            },
            {
              href: routes.account_app_integration({ id: app_integration.id }),
              color: "error",
              variant: "contained",
              label: i18n.t("delete"),
              method: "delete",
            },
          ]}
        >
          <I18n are_you_sure_to_delete_model model={app_integration.app.name} />
          &nbsp;
          <I18n this_action_cannot_be_undone />
        </Confirm>
        <Confirm
          open={openSwitch}
          onClose={onCloseSwitchConfirm}
          title={i18n.t("app_integration_switch_to_off")}
          actions={[
            {
              onClick: onCloseSwitchConfirm,
              label: i18n.t("cancel"),
            },
            {
              onClick: onCloseSwitchConfirmWithSetActiveFalse,
              label: i18n.t("confirm"),
              variant: "contained",
              primary: true,
            },
          ]}
        >
          <I18n are_you_sure />
        </Confirm>
        <Confirm
          open={openPseudoBankAccount}
          onClose={onClosePseudoBankAccountConfirm}
          title={i18n.t("app_integration_switch_pseudo_bank_account")}
          actions={[
            {
              onClick: onClosePseudoBankAccountConfirm,
              label: i18n.t("confirm"),
            },
          ]}
        />
      </Form>
    </>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("app_integrations")}
      icon={PencilIcon}
    >
      <EditDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
